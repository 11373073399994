/* eslint-disable  */
import logo from './logo.svg';
import './App.css';
import React, {useState} from 'react';
import styled from 'styled-components';

const AllWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TranslationWrapper = styled.div`
    display: flex;
    width: 50%;
    justify-content: center;
    margin-top: 50px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const SeeCode = styled.div`
  position: fixed;
  bottom: 0;
  text-align: center;
  padding: 15px 0 15px 0;
`;

const ERROR_LOGGING = true;

function LOG(err){
    if(ERROR_LOGGING){
        console.error(err);
    }
    return;
}

function getTranslation(text, firstLanguage, secondLanguage){
    let body = JSON.stringify({
        "key": '61573787-23c0-4370-86ed-98e2fcd86d73',
        "source": {"dialect": firstLanguage, "text": text},
        "target": {"dialect": secondLanguage}
    });

    let options = {
        method: 'POST',
        body: body
    };

    return fetch('https://api.itranslate.com/translate/v1', options)
        .then((res) => res.json())
        .catch((err) => LOG(err));
}

function Textareas(){
    const [isLoading, setIsLoading] = useState(false);
    const [timeOutCall, setTimeOutCall] = useState(false);
    const [firstValue, setFirstValue] = useState();
    const [secondValue, setSecondValue] = useState();

    const handleChange = ({target}) => {
        target.id == 'firstTextarea' ? setFirstValue(target.value) : setSecondValue(target.value);

        clearTimeout(timeOutCall);

        setTimeOutCall(setTimeout(function(){
            if(target.value.length >= 3) {
                let firstLanguage = target.id == 'firstTextarea' ? 'de' : 'en';
                let secondLanguage = target.id == 'firstTextarea' ? 'en' : 'de';

                setIsLoading(true);
                getTranslation(target.value, firstLanguage, secondLanguage)
                    .then(({target: {text: translation}}) => {
                        target.id == 'firstTextarea' ? setSecondValue(translation) : setFirstValue(translation);
                        setIsLoading(false);
                    });
            }
        }, 500));
    }

    return(
        <AllWrapper>
            <img src={logo} className={isLoading ? 'App-logo isLoading' : 'App-logo'} alt="logo" />
            <TranslationWrapper>
                <textarea value={firstValue} id="firstTextarea" placeholder="German Text here..." onChange={handleChange}></textarea>
                <textarea value={secondValue} id="secondTextarea" placeholder="English Text here..." onChange={handleChange}></textarea>
            </TranslationWrapper>
            <SeeCode className="codeLine"><a href="https://pastebin.com/xuhCHQ2J" target="_blank">See Code</a></SeeCode>
        </AllWrapper>
    );
}


function App() {
  return (
    <div className="App">
      <header className="App-header">

        <Textareas />

      </header>
    </div>
  );
}

export default App;
